.full-screen-modal {
    position: fixed;
    left: 0;
    right: 0;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: 3;
    transition: bottom 0.5s;
    background-color: $saleyard-white;
    overflow: auto;
    &:not(.shown) {
        bottom: 0;
    }
    form {
        font-size: $sm-size;
    }
    .auction-heading-wrap {
        h2 {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}
