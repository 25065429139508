.mat-primary {
    &:not([disabled]).mat-mdc-outlined-button {
        border-color: $saleyard-primary;
    }
}

.mat-warn {
    &:not([disabled]).mat-mdc-outlined-button {
        border-color: #f44336;
    }
}

.mat-mdc-outlined-button > .mat-icon {
    margin: 0 !important;
}
