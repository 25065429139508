.lot-bidding-card {
    padding-top: $gutter !important;

    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1) !important;
    .price-section {
        margin-top: $gutter;
    }
    .bidding-section {
        padding: calc($gutter * 2) !important;
        flex: 1 1 auto;

        .flex-1-1-auto {
            flex: 1 1 auto;
        }
    }
}

.offeredToCurrentUser {
    @media screen and (max-width: 600px) {
        .bidding-section {
            display: none !important;
        }
        .lot-bidding-card {
            font-size: 70%;
            padding: 0 16px;
            .price-section {
                margin-top: calc($gutter / 3);
            }
            .label {
                padding: calc($gutter / 3) calc($gutter / 2);
            }
        }
    }
    .user-bid-info-section {
        margin-top: calc($gutter / 3) !important;
        margin-bottom: 10px !important;
        height: auto;
    }
    .auction-lot-table.pick-children-table {
        margin-top: $gutter;
    }
}

@media screen and (max-width: 599px) {
    .lot-bidding-card {
        box-shadow: none !important;
        padding-top: 5px !important;
        .price-section {
            margin-top: 0;
        }
        .bidding-section {
            padding: $gutter !important;
        }
    }
    .lot-metrics-wrap {
        margin-top: 0 !important;
        .flex-wrap {
            flex-direction: column;
            .first-col,
            .second-col {
                flex: 0 0 100% !important;
            }
            .first-col {
                border-right: 0 !important;
                .col-content-wrap {
                    flex-direction: row !important;

                    & > div {
                        border-right: 1px solid $saleyard-darker-grey;
                        &:last-child {
                            border-bottom: 1px solid $saleyard-darker-grey;
                            border-right: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 600px) {
    .lot-metrics-wrap {
        .flex-wrap {
            flex-direction: row !important;
            flex-wrap: nowrap !important;
        }
    }
}

.lot-metrics-wrap {
    border: 1px solid $saleyard-darker-grey;
    color: $saleyard-darker2-grey !important;
    background-color: $saleyard-white;
    margin-top: calc($gutter * 2);
    font-size: 0.875em;
    .flex-wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        .first-col {
            flex: 0 0 150px;
            border-right: 1px solid $saleyard-darker-grey;
            display: flex;
            align-items: stretch;
            .col-content-wrap {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                .item {
                    border-bottom: 1px solid $saleyard-darker-grey;
                    padding: $gutter;
                    align-items: center;
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: auto;
                    display: flex;
                }

                @media screen and (min-width: 599px) {
                    & > div:last-child {
                        border-bottom: 0;
                    }
                }

                strong {
                    display: block;
                    font-size: 1.0625em !important;
                }
            }
        }
        .second-col {
            flex: 1;
            padding-left: $gutter;
            padding-right: $gutter;
            align-items: center;
            display: flex;
            .col-content-wrap {
                width: 100%;
                .item {
                    padding: calc($gutter / 3) 0;
                    border-bottom: 1px solid $saleyard-darker-grey;
                    display: flex;
                    & > strong {
                        width: 40%;
                        display: block;
                        font-size: 14px !important;
                    }
                    & > span {
                        width: 40%;
                        padding-right: calc($gutter / 3);
                        display: block;
                        font-size: 14px !important;
                    }
                }
                & > div:last-child {
                    border-bottom: 0;
                }
            }
        }
    }
    strong {
        color: $saleyard-dark-grey !important;
        &.warning {
            color: $saleyard-warning !important;
        }
    }
}

@media screen and (min-width: 960px) and (max-width: 1919px) {
    .bidding-column-wrap.hasSecondLiveStream {
        position: relative;
        app-auction-inactive {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 10px;
            background-color: #d8400e;
        }
    }
}

.bidding-column-wrap {
    .no-auction-screen {
        padding-left: 30px;
        padding-right: 30px;
        min-height: 50vh;
        .auction-title {
            font-size: 45px !important;
            line-height: 60px !important;
        }
        .screen-message {
            font-size: 20px !important;
            padding-top: 20px !important;
            padding-bottom: 35px !important;
        }
        .sub-title {
            font-size: 20px !important;
            line-height: 25px !important;
        }
        .open-icon {
            padding-top: 50px !important;
            padding-bottom: 15px !important;
            height: 100% !important;
            width: 50px !important;
        }
    }
}

#app-tally-select-panel {
    &.confirmStep {
        .mat-form-field-appearance-outline .mat-form-field-outline-thick {
            opacity: 1;
            color: $saleyard-white;
        }

        .mat-input-element,
        .mat-form-field-label {
            color: $saleyard-primary !important;
        }
        .error-message {
            visibility: hidden;
        }
    }

    .mat-mdc-outlined-button {
        background-color: $saleyard-white;
    }
    .panel-content-container {
        .mat-form-field-appearance-outline {
            .mat-form-field-wrapper {
                padding-bottom: 0 !important;
            }
        }
    }
}
