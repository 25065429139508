//saleyard
$saleyard-light-grey: #f8f8f8;
$saleyard-grey: #d8d8d8;
$saleyard-primary: #d8400e;
$saleyard-warning: #e02020;
$saleyard-darker-grey: #e6e6e6;
$saleyard-darker2-grey: #aaaaaa;
$saleyard-dark-grey: #3c4c54;
$saleyard-dark-icon: #3b4c54;
$saleyard-white: #ffffff;
$saleyard-black: #000;
$saleyard-light-grey: #e3e3e3;
$saleyard-lighter-grey: #eff1f1;
$saleyard-green: #33e020;
