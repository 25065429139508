$spacer-sizes: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25;
$spacer-unit-size: 3px;

@mixin define-padding() {
    @each $ss in $spacer-sizes {
        .p-#{$ss} {
            padding: $ss * $spacer-unit-size !important;
        }
        .px-#{$ss} {
            padding-left: $ss * $spacer-unit-size !important;
            padding-right: $ss * $spacer-unit-size !important;
        }
        .py-#{$ss} {
            padding-top: $ss * $spacer-unit-size !important;
            padding-bottom: $ss * $spacer-unit-size !important;
        }
        .pl-#{$ss} {
            padding-left: $ss * $spacer-unit-size !important;
        }
        .pr-#{$ss} {
            padding-right: $ss * $spacer-unit-size !important;
        }
        .pt-#{$ss} {
            padding-top: $ss * $spacer-unit-size !important;
        }
        .pb-#{$ss} {
            padding-bottom: $ss * $spacer-unit-size !important;
        }
    }
}

@mixin define-margin() {
    @each $ss in $spacer-sizes {
        .m-#{$ss} {
            margin: $ss * $spacer-unit-size !important;
        }
        .mx-#{$ss} {
            margin-left: $ss * $spacer-unit-size !important;
            margin-right: $ss * $spacer-unit-size !important;
        }
        .my-#{$ss} {
            margin-top: $ss * $spacer-unit-size !important;
            margin-bottom: $ss * $spacer-unit-size !important;
        }
        .ml-#{$ss} {
            margin-left: $ss * $spacer-unit-size !important;
        }
        .mr-#{$ss} {
            margin-right: $ss * $spacer-unit-size !important;
        }
        .mt-#{$ss} {
            margin-top: $ss * $spacer-unit-size !important;
        }
        .mb-#{$ss} {
            margin-bottom: $ss * $spacer-unit-size !important;
        }
    }
}

@mixin define-negative-margin() {
    @each $ss in $spacer-sizes {
        .mx--#{$ss} {
            margin-left: -$ss * $spacer-unit-size !important;
            margin-right: -$ss * $spacer-unit-size !important;
        }
        .my--#{$ss} {
            margin-top: -$ss * $spacer-unit-size !important;
            margin-bottom: -$ss * $spacer-unit-size !important;
        }
        .ml--#{$ss} {
            margin-left: -$ss * $spacer-unit-size !important;
        }
        .mr--#{$ss} {
            margin-right: -$ss * $spacer-unit-size !important;
        }
        .mt--#{$ss} {
            margin-top: -$ss * $spacer-unit-size !important;
        }
        .mb--#{$ss} {
            margin-bottom: -$ss * $spacer-unit-size !important;
        }
    }
}

@include define-padding();
@include define-margin();
@include define-negative-margin();

$size-spacer-sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
    95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150, 175, 200, 250;
$size-spacer-unit-size: 5px;

@mixin define-width-sizer($append) {
    @each $ss in $size-spacer-sizes {
        .max-w-#{$ss}#{$append} {
            max-width: $ss * ($size-spacer-unit-size) !important;
        }
        .max-h-#{$ss}#{$append} {
            max-height: $ss * ($size-spacer-unit-size * 2) !important;
        }
        .fixed-w-#{$ss}#{$append} {
            width: $ss * ($size-spacer-unit-size);
        }
        .min-w-#{$ss}#{$append} {
            min-width: $ss * ($size-spacer-unit-size);
        }
    }
}

@include define-width-sizer('');

@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}
