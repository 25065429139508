.bid-list-container {
    .withdrawn {
        text-decoration: line-through;
        opacity: 0.75;
    }

    tr.bid-colour-red {
        &,
        td {
            background-color: rgba($saleyard-warning, 0.2) !important;
        }
    }

    tr.bid-colour-green {
        &,
        td {
            background-color: rgba($saleyard-green, 0.2) !important;
        }
    }

    tr.floor-bidder {
        &,
        td {
            background-color: rgba(#fff, 0.2) !important;
        }
    }

    .bid-list-container__list {
        display: grid;
        grid-template-rows: 48% 24% 24%;
        gap: 16px;
        height: calc(100vh - 130px);

        .bid-list-container__item {
            border: solid 2px #f1f1f1;
            box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
            height: 100%;
            position: relative;
            overflow: hidden;
            width: 100%;

            .bid-list-container__number {
                font-size: 40px;
                position: absolute;
                left: 10px;
                top: 10px;
            }

            .bid-list-container__amount {
                font-size: 60px;
                display: grid;
                align-items: center;
                justify-items: center;
                height: 100%;
            }
        }

        .bid-list-container__item:first-child {
            .bid-list-container__amount {
                font-size: 80px;
                font-weight: bold;
            }
        }

        .bid-colour-red {
            background-color: rgba($saleyard-warning, 0.5) !important;
            border: solid 2px rgba($saleyard-warning, 0.5);
        }

        .bid-colour-green {
            background-color: rgba($saleyard-green, 0.5) !important;
            border: solid 2px rgba($saleyard-green, 0.5);
        }

        .floor-bidder {
            background-color: rgba(#fff, 0.5) !important;
            border: solid 2px rgba(#f1f1f1, 1);
        }

        @keyframes outline {
            0% {
                border: 2px solid #d8400e;
                color: #d8400e;
            }
            50% {
                border: 2px solid #fff;
                color: #f1f1f1;
            }
            100% {
                border: 2px solid #d8400e;
                color: #d8400e;
            }
        }

        .bid-list-container__item:first-child,
        .bid-list-container__item--animated {
            animation: outline 0.2s ease-in-out;
            animation-iteration-count: 1;
        }
    }
}

.lot-list-container,
.bid-list-container {
    .table-wrap {
        max-height: calc(var(--vh, 1vh) * 40);
        overflow: auto;
    }

    .selected {
        &,
        td {
            background-color: $saleyard-primary !important;
            color: $saleyard-white;
            svg {
                path {
                    fill: $saleyard-white;
                }
            }

            .lot-list-container__media-icon {
                .mat-icon {
                    color: #fff !important;
                    transition: 0.5s;
                }
            }
        }
    }
    .not-selectable:not(.online-buyer) {
        opacity: 0.6;
    }
    .online-buyer td {
        background-color: rgba(#8bc34a, 0.4) !important;
    }
    thead {
        position: relative;
        z-index: 2;
    }
    th {
        background-color: $saleyard-white;
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        z-index: 2;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.54);
        padding-bottom: 0;
    }

    table.table-striped tr:nth-of-type(odd) td {
        background-color: #fdfdfd;
        padding: 0;
    }

    table.table-striped tr:nth-of-type(even) td {
        background: #f5f5f5;
        padding: 0;
    }

    .lot-list-container__width-90 {
        width: 90px;
    }

    .lot-list-container__media-icon {
        float: left;
        margin-right: 10px;
        margin-bottom: -8px;
        position: relative;
        width: 30px;

        .mat-icon {
            color: $saleyard-primary;
            transition: 0.5s;

            svg {
                width: 12px;
            }
        }

        .lot-list-container__media-icon-count {
            background: #fa7546;
            color: #fff;
            border-radius: 50%;
            font-size: 12px;
            font-weight: bold;
            height: 18px;
            padding-top: 0;
            position: absolute;
            right: -5px;
            text-align: center;
            top: -5px;
            transition: 0.5s;
            width: 18px;
        }
    }
}

.attendee-list-container {
    .selected {
        &,
        td {
            background-color: $saleyard-primary !important;
            color: $saleyard-white;
        }
    }

    .stopCredit {
        &,
        td {
            color: red;
        }
    }
}

.lot-list-container,
.bidders-list-container {
    .table-wrap {
        max-height: calc(var(--vh, 1vh) * 30);
        .mat-mdc-header-row {
            height: calc($gutter * 2);
        }
        .mat-mdc-row {
            height: auto;
            td,
            td strong {
                font-size: 14px !important;
                line-height: 1.5;
                padding: 0 6px;
            }

            td:first-child {
                padding-left: 24px;
            }
        }
    }
    &.past {
        .table-wrap {
            max-height: calc(var(--vh, 1vh) * 80);
        }
        .online-buyer td {
            background-color: rgba(#8bc34a, 0.4) !important;
        }
    }

    .mat-mdc-form-field-outline {
        color: $saleyard-primary;
    }

    .mat-form-field-appearance-outline {
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }
    }
}

.lot-list-container.pick-offer {
    .checkbox-like {
        position: relative;
        top: 2px;
    }
    .not-selectable {
        .checkbox-like {
            visibility: hidden !important;
        }
    }
    .mat-column-lotNumber {
        padding-left: 5px !important;
    }
    .table-wrap {
        max-height: calc(var(--vh, 1vh) * 40);
    }
    .mat-icon {
        visibility: hidden;
    }
    .clickable {
        .mat-icon {
            visibility: visible;
        }
    }
}

.preset-bid-list-container {
    .table-wrap {
        max-height: calc(var(--vh, 1vh) * 60);
    }
}

div.auction-card-wrap {
    .mat-icon {
        display: none !important;
    }
}

div.auction-card-wrap + div.saleyard-lot-list {
    .table-wrap {
        //height: calc(var(--vh, 1vh) * 15);
    }
}

.increment-btns-wrap {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(var(--vh, 1vh) * 40);
    overflow: auto;

    div {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100;
    }

    button {
        height: 46px;
        padding: 5px 15px;
        white-space: nowrap;
    }
}

.main-menu {
    a {
        position: relative;
        padding-left: 30px;
        width: 100%;
        font-size: 16px;

        .mat-icon {
            left: 0;
            position: absolute;
            top: -2px;
        }
    }

    .mat-mdc-list-item {
        padding-left: 10px;
    }
}

.table-wrap {
    overflow: auto;
}

.auctions-table {
    .mat-mdc-header-cell {
        background-color: $saleyard-light-grey;
        color: $saleyard-black;
    }
}

.auctions-table,
.past.lot-list-container {
    td,
    td button {
        font-size: 12px !important;
        line-height: 15px;

        &.mat-default .mat-icon {
            font-size: 20px !important;
        }
    }
    .mat-column-actions {
        text-align: center !important;
    }
}

.auction-list-filter {
    background-color: $saleyard-light-grey;

    .mat-mdc-form-field-flex {
        position: relative;
        &::before {
            top: $gutter;
            bottom: 0;
            left: 0;
            right: 0;
            @include pseudo();
        }

        padding: 0 10px;
    }

    .mdc-text-field--filled {
        background: none;
    }

    .mat-mdc-form-field-focus-overlay {
        display: none;
    }

    .mat-form-field-label {
        color: $saleyard-black;
    }

    .mat-mdc-text-field-wrapper {
        padding-bottom: 0 !important;

        .mat-form-field--underline {
            display: none !important;
        }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__trailing {
        border-width: 0 !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: $saleyard-black;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
        color: $saleyard-primary;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
        display: none;
    }

    .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
        background: #fff;
        padding: 4px 10px;
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 32px;
    }

    .mat-mdc-select {
        background: $saleyard-white;
        padding: 4px 10px;
    }

    .mat-mdc-form-field-icon-suffix {
        position: absolute;
        right: 4px;
    }

    .dates-wrap {
        @media only screen and (min-width: 800px) {
            width: 360px !important;
            min-width: 360px !important;
            max-width: 360px !important;

            .mat-mdc-form-field {
                max-width: 160px;
            }
        }
    }

    .title-wrap {
        max-width: 250px;
    }

    .saleyards-wrap {
        max-width: 400px;
    }

    .from-field-error {
        margin: -15px 0 0 5px;
    }

    .action-wrap {
        white-space: nowrap !important;

        button {
            white-space: nowrap !important;
        }
    }
}

.overflow-y-auto {
    overflow-y: auto;
}

.bid-offers-cards-wrap {
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
        background-color: rgba($saleyard-primary, 0.2) !important;
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
        color: #000 !important;
    }

    .mat-mdc-standard-chip {
        height: auto;
        min-height: 0;

        &.mat-chip-selected.mat-primary:not(.rejected) {
            background-color: rgba($saleyard-primary, 0.2);
            color: $saleyard-black;
        }
    }
    .mat-mdc-card {
        box-shadow: none;
        &.highlight-row {
            border: 1px solid $saleyard-primary;
        }
        &:not(.highlight-row) {
            border: 1px solid $saleyard-grey;
        }
    }
    .mat-mdc-card-actions {
        justify-content: space-between;
    }
}

.auction-list-container {
    .mdc-notched-outline > * {
        border: none !important;
        border-bottom: solid 1px $saleyard-primary !important;
        border-radius: 0 !important;
    }

    .mat-mdc-select-arrow-wrapper {
        margin-right: 10px;
    }
}

.mat-mdc-form-field-subscript-wrapper {
    height: 17.5px;
}
