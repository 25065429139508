.buyer-auction-wrapper {
    @import './general/grid.scss';

    @import './components/owl.scss';
    @import './components/mat-expansion-panel.scss';
    @import './components/auction-lot-table.scss';
    @import './components/carousel.scss';
    @import './components/lot-header.scss';
    @import './components/lot-bidding.scss';
}

.buyer-layout-wrap {
    background-color: $saleyard-light-grey;
    min-height: 100vh;
    @import './components/nav.scss';

    @media screen and (min-width: 1279px) {
        padding-left: 105px;
    }
}
