as-last-lot {
    display: grid;
    align-self: self-end !important;
}

.as-last-lot {
    align-items: center;
    background: #f1f1f1;
    display: grid;
    font-size: 36px;
    gap: 8px;
    height: 350px;
    margin-bottom: 16px;
    padding: 16px;

    .text-primary {
        font-size: 40px;
        font-weight: bold;
    }

    &__item {
        display: grid;
        gap: 8px;
        grid-template-columns: 68% 30%;
        font-weight: bold;
        text-align: left;

        .as-last-lot__item-text,
        .as-last-lot__item-value,
        .as-last-lot__item-user {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &__item:last-child {
        margin: 0;
    }

    &__item span:last-child {
        text-align: right;
    }

    &__item-user:last-child {
        text-align: left !important;
    }

    .as-last-lot__item:last-child {
        margin-top: 0;
    }
}
