.iframe-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    &:before {
        padding-top: 56.25%;
        display: block;
        content: '';
    }
    & > iframe,
    & > video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    &.iframe-responsive-16by9 {
        &:before {
            padding-top: 56.25%;
        }
    }
}

.aspect-ratio-wrap {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    &:before {
        padding-top: 100%;
        display: block;
        content: '';
    }
    &.aspect-ratio-16-9 {
        &:before {
            padding-top: 56.25%;
        }
    }
    &.aspect-ratio-1-1 {
        &:before {
            padding-top: 100%;
        }
    }

    &.aspect-ratio-3-4 {
        &:before {
            padding-top: 75%;
        }
    }
    & > div,
    & > video,
    & > vg-player,
    & > iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

.owl-nav {
    & > div {
        width: calc($gutter / 2);
        height: calc($gutter / 2);
        background: $saleyard-white;
        position: absolute;
        margin-top: -22px;
        top: 50%;
        cursor: pointer;
        width: 44px;
        height: 44px;
        @include matIcons();
        text-align: center;
        line-height: 44px !important;
        color: $saleyard-primary;
        &.disabled {
            display: none !important;
        }
    }
    .owl-prev {
        left: 0;
        right: auto;
    }
    .owl-next {
        left: auto;
        right: 0;
    }
}

@media screen and (max-width: 1279px) {
    .cam-tag.cam-primary {
        display: none !important;
    }
}

.cam-tag {
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
}

.media-placeholder {
    .mat-mdc-progress-spinner {
        position: absolute;
        top: 50%;
        margin-top: -$gutter;
        left: 50%;
        margin-left: -$gutter;
        &,
        & svg {
            width: calc($gutter / 2) !important;
            height: calc($gutter / 2) !important;
        }
    }
}

.media-placeholder,
.buyer-gallery-thumbs {
    .lot-number-tag {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $saleyard-white;
        width: auto;
        height: auto;
        left: auto;
        bottom: auto;
        z-index: 3;
    }
}

.buyer-gallery-thumbs {
    .lot-number-tag {
        font-size: $sm-size;
    }
    .owl-item,
    .item {
        max-width: 90px;
        .slide-inner {
            box-sizing: border-box;
            border: 1px solid $saleyard-light-grey;
            background-color: $saleyard-darker-grey;
            &:after {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($saleyard-dark-grey, 0.15);
            }
            &.selected {
                border: 1px solid $saleyard-primary;
            }
            &:hover,
            &.selected {
                &:after {
                    display: none !important;
                }
                .icon {
                    color: $saleyard-primary;
                }
            }
            .text,
            .icon {
                position: absolute;
                text-align: center;
                top: 50%;
                text-transform: uppercase;
                left: 0;
                right: 0;
            }
            .text {
                margin-top: -20px;
                color: $saleyard-dark-grey;
                line-height: 20px;
            }
            .icon {
                color: $saleyard-dark-grey;
                position: absolute;
                text-align: center;
                margin-top: -$gutter;
                .mat-icon {
                    width: calc($gutter / 2);
                    height: calc($gutter / 2);
                    font-size: calc($gutter / 2);
                }
            }
        }
    }

    .row {
        margin-left: -(calc($gutter * 0.2));
        margin-right: -(calc($gutter * 0.2));
        clear: both;
        .item {
            box-sizing: border-box;
            width: 25%;
            float: left;
            padding-bottom: calc($gutter / 2.5);
            padding-left: calc($gutter / 5);
            padding-right: calc($gutter / 5);
            display: block;
        }
    }
}

.media-detail-wrap {
    position: relative;
    .next-slide,
    .prev-slide {
        position: absolute;
        bottom: 25%;
        margin-bottom: -22px;
        box-shadow: none !important;
        min-width: 0 !important;
        padding: 4px 10px;
        left: auto;
        right: auto;
        z-index: 500;
        border-radius: 0 !important;

        @media only screen and (max-width: 600px) {
            .next-slide,
            .prev-slide {
                display: none !important;
            }
        }
    }

    .prev-slide {
        left: 0;
    }

    .next-slide {
        right: 0;
    }

    &:not(.has-primary-cam) {
        .next-slide,
        .prev-slide {
            bottom: 50%;
        }
    }

    .media-detail-wrap__expand-image {
        background: #fff;
        position: absolute;
        bottom: 10px;
        box-shadow: none !important;
        min-width: 0 !important;
        padding: 4px 10px;
        right: 10px;
        z-index: 500;
        border-radius: 0 !important;
        padding: 0;
        width: 40px;
        height: 40px;

        @media screen and (max-width: 720px) {
            bottom: 5px;
            right: 5px;
            height: 30px;
            width: 30px;
        }

        .mat-icon {
            margin: auto !important;
        }

        .mat-mdc-button-touch-target {
            width: 40px;
            text-align: center;
        }
    }

    .media-detail-wrap__close {
        background: #fff;
        position: absolute;
        top: 10px;
        box-shadow: none !important;
        min-width: 0 !important;
        padding: 4px 10px;
        right: 10px;
        z-index: 500;
        border-radius: 0 !important;
        padding: 0;
        width: 40px;
        height: 40px;

        .mat-icon {
            margin: auto !important;
        }
    }

    .media-detail-wrap__fullscreen-overlay {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
    }

    .media-detail-wrap__fullscreen {
        display: block;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px;
        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        z-index: 1000;
    }

    .media-detail-wrap__fullscreen-image {
        max-width: 95vw;
        max-height: 95vh;
        margin: auto;
        display: block;
    }
}
