.third-col {
    .preset-announcement-list {
        display: block;
        height: calc(var(--vh, 1vh) * 40);
        overflow-y: auto;
        table {
            thead {
                div {
                    position: relative;
                    button {
                        position: absolute;
                        right: -10px;
                        top: -10px;
                        width: 25px;
                        height: 25px;
                    }
                }
            }
        }
    }
}

.mat-mdc-list-base {
    padding: 0;
}

.auction-title {
    .mdc-list-item__content {
        display: block !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-top: 0px;
        margin-right: -5px;
        font-size: 16px !important;
    }

    .mdc-list-item__primary-text {
        font-size: 16px !important;
    }
}

.auction-subheading,
.auction-title .mdc-list-item__content {
    small {
        .mat-icon {
            font-size: 12px !important;
            margin-right: 5px;
            color: $saleyard-black;
        }
    }
}

.auction-heading-wrap {
    &.hasAuctionHeading {
        h2 {
            position: relative;
            &:before {
                z-index: -1;
                @include pseudo();
                left: -50px;
                right: -50px;
                top: 0;
                bottom: 0;
                background-color: $saleyard-white;
            }
        }
    }
    &:not(.hasAuctionHeading) {
        h2 {
            padding-left: $gutter;
        }
    }
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0.25);
}

.pending-increment-text {
    position: absolute;
    top: 10px;
    right: 8px;
    left: 75px;
    width: auto;
}

.p-relative {
    position: relative;
}

.invisible {
    visibility: hidden !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.mat-checkbox-group {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
    .generalOption {
        display: none !important;
    }
    &[formcontrolname='saleTypeId'] {
    }
}

.Lot-lotNumber {
    .mat-icon {
        height: 24px !important;
        position: relative;
        background: $saleyard-primary;
        border-radius: 100%;
        margin-top: 2px;
        float: left;
        text-align: center;
        padding: 2px;
        color: $saleyard-lighter-grey;
    }
}

.Lot-lotNumber2 {
    .mat-icon {
        margin-top: 4px;
        float: none;
    }
}

mat-icon[ng-reflect-svg-icon='private-message'] {
    svg {
        width: 24px;
        height: 24px;
        position: relative;
        bottom: -2px;
        right: -2px;
        path {
            stroke-width: 2px;
            stroke: $saleyard-dark-icon;
            fill: $saleyard-dark-icon;
        }
    }
}

.auction-play-resume-announcement-control-panel-wrap {
    mat-icon[ng-reflect-svg-icon='private-message'] {
        svg {
            display: inline-block;
            width: 32px;
            height: 32px;
            bottom: -3px;
            right: -2px;
            path {
                stroke-width: 3px;
            }
        }
    }
}

// Progress bar
.mat-progress-bar::after {
    background-color: #d8400e !important;
}

.mat-progress-bar-buffer {
    background-color: #fdcebf;
}

.bg-dark {
    background-color: #d8d8d8 !important;
}

.mat-drawer-container {
    background-color: #f8f8f8 !important;
    color: #3c4c54 !important;
}

.mat-icon {
    height: unset;
    overflow: unset;
}

// form field

.mat-mdc-form-field.mat-focused .mat-form-field-label {
    color: #d8400e !important;
}

.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
    background-color: #d8400e !important;
}

.start-price-text {
    font-size: 31px !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
    background: #d8400e !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
    color: #fff !important;
}
