@use 'sass:math';

*,
*:before,
*:after {
    box-sizing: border-box;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -(calc($gutter / 3));
    margin-right: -(calc($gutter / 3));

    & > div {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
        padding: calc($gutter / 3);
        text-align: center;
    }
}

.none-flex {
    flex: none !important;
}

.mat-mdc-tab-body-content {
    padding-left: calc($gutter / 3);
    padding-right: calc($gutter / 3);
    overflow: hidden;
}

.form-wrap {
    .flex-row {
        & > div {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.drawer-wrap {
    padding: $gutter;
    min-width: 25vw;
}

.mat-drawer-inner-container {
    position: relative;
    padding-top: 40px;

    .menu-btn {
        position: absolute;
        right: 0;
        left: auto;
        top: 0;
    }

    a {
        color: $saleyard-dark-grey;
        text-decoration: none;
        &.active,
        &:hover {
            color: $saleyard-primary !important;
        }
    }

    .mat-mdc-list-item:not(.mat-mdc-list-item-interactive),
    .mat-mdc-list-option:not(.mat-mdc-list-item-interactive) {
        border-bottom: solid 1px #d9d9d9;
    }
}

.full-height {
    height: calc(var(--vh, 1vh) * 100);
    .mat-grid-list {
        padding-bottom: 0 !important;
    }
    .col {
        height: calc(var(--vh, 1vh) * 100);
        overflow-y: auto;
        box-sizing: border-box;
        padding-top: 0 !important;
        & > div {
            padding: 10px !important;
            @media only screen and (min-width: 900px) {
                padding: $gutter !important;
            }
            box-sizing: border-box;
            flex-wrap: wrap;

            .align-start {
                align-self: flex-start;
                -webkit-align-self: flex-start;
            }
            .align-center {
                align-self: flex-end;
                -webkit-align-self: flex-end;
            }
            .align-end {
                align-self: flex-end;
                -webkit-align-self: flex-end;
            }
            .grow {
                flex: 1;
            }
        }
    }
    .mat-grid-tile {
        z-index: 2;
    }
}

.has-gray-fake-col {
    padding-left: calc($gutter * 6);
    &:before {
        @include pseudo();
        position: fixed;
        top: 0;
        left: 0;
        width: calc($gutter * 5.25);
        background-color: $saleyard-grey;
        bottom: 0;
    }
    & > .mat-grid-tile-content {
        left: 80px;
    }
}
