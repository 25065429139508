// Custom CSS to override the theme
.mat-mdc-form-field.mat-focused .mat-form-field-label {
    color: #d8400e !important;
}

.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
    background-color: #d8400e !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control,
.form-control[disabled]:focus,
.form-control[readonly]:focus,
fieldset[disabled] .form-control:focus {
    background-color: #eee;
    opacity: 1;
}

.mdc-text-field {
    padding: 0 !important;
}

.mdc-text-field--filled {
    background: none !important;
}

.mat-mdc-form-field-focus-overlay {
    display: none;
}

.mat-mdc-menu-content .mat-mdc-menu-item.sm-size {
    min-height: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
}

.mat-mdc-text-field-wrapper {
    height: 45px !important;
}

.preset-announcement-textarea,
.quick-announcement,
.textarea-override {
    .mat-mdc-text-field-wrapper {
        height: auto !important;
    }
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-bottom: 0;
    padding-top: 18px;
}

.preset-announcement-textarea,
.quick-announcement,
.textarea-override {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
        padding-bottom: 0;
        padding-top: 22px;
    }
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input,
.mat-mdc-checkbox label,
.mat-mdc-select-value {
    font-size: 14px;
    letter-spacing: 0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    color: #000 !important;
    font-size: 16px !important;
}

.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-form-field .mdc-text-field--filled .mat-mdc-form-field-infix {
    margin-top: 10px !important;
}

.mat-calendar-arrow {
    margin-top: 8px !important;
}

.time-container {
    height: 90px;
}

.mat-mdc-form-field-hint-wrapper {
    padding: 0 !important;
}

.mat-mdc-dialog-container .mat-dialog-actions .mdc-button {
    margin-right: 10px;
}

.mat-dialog-content .mat-mdc-form-field {
    padding-left: 0 !important;
}

.mat-mdc-table button {
    white-space: nowrap !important;
}

.mdc-dialog__actions {
    padding: 0 !important;
}

.mat-mdc-header-cell {
    white-space: nowrap !important;
    font-size: 12px !important;

    @media screen and (max-width: 768px) {
        white-space: normal !important;
    }
}

.mat-form-field-appearance-outline .mdc-text-field__input {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    height: 54px !important;
}

.preset-announcement-textarea {
    textarea {
        font-size: 16px !important;
        line-height: 16px !important;
    }
}

.mat-calendar-body-disabled {
    opacity: 0.5;
}

.mat-mdc-floating-label {
    font-size: 15px !important;
    letter-spacing: 0 !important;
}

.mat-mdc-radio-button {
    font-size: 16px !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
    font-size: 15px !important;
    font-weight: 400 !important;
    letter-spacing: 0;
}
