.auction-lot-table {
    background-color: transparent !important;
    table {
        .mat-mdc-header-row,
        .mat-mdc-row:not(.currentLot) {
            background-color: $saleyard-light-grey !important;
        }
    }
    .table-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.pick-child-lots-wrap,
.runout-child-lots-wrap {
    padding-top: $gutter;
    .auction-lot-table {
        .table-container {
            max-height: 350px;
            overflow-y: auto;
        }
        table {
            .mat-mdc-header-row,
            .mat-mdc-row {
                height: auto;
                background-color: $saleyard-white !important;
                td,
                th {
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                th {
                    font-size: 15px;
                    line-height: 18px;
                    color: $saleyard-black;
                    border-bottom: 0px;
                }
                td {
                    background-color: $saleyard-white;
                    border-top: 1px solid $saleyard-light-grey;
                    font-size: 13px;
                    line-height: 16px;
                    color: $saleyard-dark-grey;
                    .mat-icon {
                        width: 13px;
                        height: 13px;
                        font-size: 9px;
                    }
                }
            }
            .mat-column-lotNumber {
                min-width: 80px;
            }
        }
    }
    tr:not(.selected) {
        &.userWonLot,
        &.sold-lot {
            td {
                color: $saleyard-primary !important;
                font-weight: 700;
            }
        }
    }
    .pick-children-table {
        tr:not(.clickable) {
            &.userWonLot,
            &.sold-lot {
                td {
                    opacity: 0.5;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .status-3,
        .status-4,
        .status-5,
        .status-6,
        .status-7,
        .status-14,
        .status-15 {
            .statusText {
                display: inline !important;
            }
        }
    }
}
