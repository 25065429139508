.mat-datepicker-content-touch .mat-datepicker-content-container.mat-datepicker-content-container-with-time {
    width: 300px;
}

.mat-calendar-table-header {
    font-size: 11px;
}

.mat-datepicker-content {
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    background-color: #fff;
    color: #000000de;
}

.mat-calendar-body-selected {
    background-color: #007f51;
    color: #fff;
}

.mat-calendar-body-cell-content {
    border: none !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border: solid 1px rgba(0, 0, 0, 0.3) !important;
    background: #000;
}
