.toast-container {
    padding-top: 10px;

    .toast-info.notification {
        // font-family: mat-font-family($bidr-typography, body-1);
        background-color: #d8400e;
        color: #fff;
    }

    /* .toast {
        background-size: 18px;
    } */

    .saleyard {
        max-width: 600px;
    }
}
