.as-head {
    .Lot-lotNumber {
        font-size: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        .text-primary {
            font-size: 40px;
        }

        .mat-mdc-chip-action-label {
            font-size: 30px;
            padding: 10px;
        }

        .mat-mdc-chip.mat-mdc-standard-chip {
            height: 40px;
        }
    }
}
