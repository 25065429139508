.nav-toolbar {
    .home-button,
    .bell-button {
        overflow: hidden;
        min-width: 0;
        border-width: 0;
    }

    .nav-toolbar {
        position: relative;
    }
}

@media screen and (min-width: 1280px) and (max-width: 5000px) {
    .nav-toolbar {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;

        .logo {
            margin-right: -3px;
        }

        .home-button,
        .bell-button {
            border-width: 1px;
            border-color: #d8400e !important;
            margin-top: $gutter;

            mat-icon {
                width: 22.5px;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .nav-toolbar {
        .home-button,
        .bell-button {
            position: absolute;
            right: calc($gutter / 5);
            top: calc($gutter / 3);
            z-index: 3;
            transform: translateY(7px);
        }

        .bell-button {
            right: 40px;
        }
    }
}

@media screen and (min-width: 599px) and (max-width: 959px) {
    .nav-toolbar {
        background-color: transparent !important;
    }
}

@media screen and (min-width: 960px) and (max-width: 1279px) {
    .nav-toolbar {
        margin-bottom: $gutter;
    }
}

@media screen and (max-width: 599px) {
    .logo {
        display: none !important;
    }
    .nav-toolbar {
        padding: 0 !important;

        .home-button,
        .bell-button {
            background-color: $saleyard-white;
            top: calc($gutter / 3);
            right: 20px;

            mat-icon {
                width: 20px;
            }
        }

        .bell-button {
            right: 60px;
        }
    }
}
