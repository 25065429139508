.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: var(--saleyard-dark-grey);
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: var(--saleyard-dark-grey);
    --mat-tab-header-active-ripple-color: var(--saleyard-dark-grey);
    --mat-tab-header-inactive-ripple-color: var(--saleyard-dark-grey);
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: var(--saleyard-dark-grey);
    --mat-tab-header-active-hover-label-text-color: var(--saleyard-dark-grey);
    --mat-tab-header-active-focus-indicator-color: var(--saleyard-dark-grey);
    --mat-tab-header-active-hover-indicator-color: var(--saleyard-dark-grey);
}

.mat-mdc-tab-group {
    .mat-mdc-tab-header {
        background-color: $saleyard-white;
        border-bottom: 0 !important;

        .mat-mdc-tab {
            color: $saleyard-black;

            .mat-icon {
                color: $saleyard-primary;
                padding: 3px;
                font-size: 16px;
                border-radius: 100%;
                margin-right: 15px;
                opacity: 0.65;
            }
        }

        .mat--mdc-tab-disabled {
            .mat-icon {
                color: $saleyard-black;
            }
        }

        .mdc-tab--active {
            background-color: $saleyard-lighter-grey;
            opacity: 1 !important;

            .mat-icon {
                color: $saleyard-primary;
                background-color: $saleyard-white;
                opacity: 1;
            }
        }
        .mat-ink-bar {
            display: none !important;
        }
    }

    .mat-mdc-tab-body-content {
        background-color: $saleyard-lighter-grey;
    }

    .mdc-tab-indicator__content--underline {
        display: none;
    }
}

.mat-mdc-tab .mdc-tab__text-label {
    font-weight: 400;
}
