$sm-size: 14px;
$md-size: 26px;
$lg-size: 32px;
$xl-size: 48px;

@use 'sass:math';

.saleyard-mo-auction-wrapper {
    @import './general/grid.scss';

    @import './components/keyboard.scss';
    @import './components/buttons.scss';
    @import './components/lists.scss';
    @import './components/auction-card.scss';
    @import './components/full-screen-modal.scss';
    @import './components/tabs.scss';
    @import './components/others.scss';
    @import './components/auctioneer-screen.scss';
    @import './components/as-lot-selling.scss';
    @import './components/as-last-lot.scss';
    @import './components/as-purchaser.scss';
    @import './components/as-users.scss';
    @import './components/as-head.scss';
}

.saleyard-mo-auction-wrapper {
    padding-top: 0;
}

@import './general/outside-wrapper-others.scss';
@import './components/calendar-with-time.scss';

@import './general/override.scss';
