*:not(.mat-icon) {
    font-family: $saleyard-primary-font;
}

h1,
h2,
h3,
h4,
h5,
strong,
b {
    font-weight: 700 !important;
    font-size: 1.5em;
}

h3 {
    font-size: 1.17em;
}

h4,
strong,
b {
    font-size: 15px;
}

.text-left {
    text-align: left !important;
}

.text-muted {
    color: $saleyard-darker2-grey;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-primary {
    color: $saleyard-primary !important;
}

.text-danger {
    color: red !important;
}

.text-white {
    color: $saleyard-white !important;
}

.text-dark {
    color: $saleyard-dark-grey !important;
}

.text-bold {
    font-weight: 700;
}

.sm-font-size {
    font-size: 12px;
}

.md-font-size {
    font-size: 14px;
    &.mat-icon,
    .mat-icon {
        height: 14px !important;
        width: 14px !important;
    }
}

.lg-font-size {
    font-size: 20px;
}

.sm-icon {
    font-size: 15px;
    width: 15px;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important; /* 2 */
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important; /* 3 */
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
}

@mixin matIcons() {
    font-family: material icons;
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
    color: #d8400e;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #d8400e;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
    background: none !important;
}

// calender
// For Calendar UI changes, they need to be higher up. Hence there are being overwritten here
.mat-calendar-body-cell-content {
    &.mat-calendar-body-selected {
        background-color: #d8400e !important;
        color: #fff !important;
    }
}

.mat-calendar-body-cell {
    &:hover {
        .mat-focus-indicator {
            background-color: rgba(60, 76, 84, 0.1) !important;
        }
        .mat-calendar-body-selected {
            background-color: #d8400e !important;
            color: #fff !important;
        }
    }
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
    background-color: unset !important;
    border-color: rgba(0, 0, 0, 0.38) !important;
}

.mat-datepicker-toggle-active {
    color: #d8400e !important;
}

// svg
img,
svg {
    vertical-align: text-top !important;
}
