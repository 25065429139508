.as-users {
    align-items: center;
    background: #fff;
    display: grid;
    font-size: 26px;
    padding: 16px;

    .text-primary {
        font-size: 30px;
        font-weight: bold;
    }
}

.as-users {
    min-height: 120px;
}

.as-users-item {
    border-bottom: solid 1px #d9d9d9;
    font-weight: bold;
    margin-bottom: 16px;
    padding-bottom: 16px;
    position: relative;
    text-align: right;

    .as-users-text {
        font-weight: normal;
        left: 0;
        position: absolute;
        top: 0;

        .mat-icon {
            margin-top: 6px;
            color: #d8400e;
            float: left;
            font-size: 30px;
        }
    }
}

.as-users-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
