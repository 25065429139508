@use '@angular/material' as mat;
@include mat.core();

@import './colors.scss';

$gutter: 15px;
$borderRadius: 4px;

$saleyard-primary-font: 'Nunito', sans-serif;

$bidr-typography: mat.m2-define-typography-level(
    $font-family: $saleyard-primary-font,
    $font-weight: 400,
    $font-size: 1rem,
    $line-height: 1,
    $letter-spacing: normal,
);

$my-custom-typography-config: mat.m2-define-typography-config(
    $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
);
