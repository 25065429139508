@use 'sass:math';

.mat-datepicker-dialog {
    .mat-mdc-dialog-container {
        padding: 0 !important;
    }
}

.saleyard-dialog-actions,
alert-dialog,
confirm-dialog {
    .mat-default {
        &.mat-mdc-raised-button {
            background-color: $saleyard-dark-grey;
            border: 1px solid $saleyard-dark-grey;
            color: $saleyard-white;
        }
        &.mat-mdc-outlined-button {
            border-color: $saleyard-dark-grey;
        }
    }
    button[disabled] {
        opacity: 0.4;
    }
}

saleyard-lot-post-sale-form {
    .mat-dialog-actions {
        margin-bottom: 0 !important;
    }
}

.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    opacity: 0 !important; /*click effect color change*/
    background-color: $saleyard-primary !important;
}

.mat-mdc-radio-button.cdk-focused {
    display: none;
}

.mat-mdc-button-focus-overlay {
    background-color: transparent !important;
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $saleyard-primary !important; /*inner circle color change*/
}

.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $saleyard-primary !important; /*outer ring color change*/
}

.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
    opacity: 0 !important; /*click effect color change*/
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $saleyard-primary !important;
}

.mat-mdc-menu-content {
    .mat-mdc-menu-item {
        &.sm-size {
            padding: calc($gutter / 3) calc($gutter / 3);
            font-size: $sm-size;
            height: auto !important;
            line-height: normal !important;
            .mat-icon {
                font-size: math.div($sm-size, 1.25);
                height: $sm-size;

                margin-top: -2px;
                line-height: $sm-size;
                width: auto;
            }
        }
        &.md-size {
            padding: calc($gutter / 2.5) calc($gutter / 1.25);
            min-height: $md-size;
            line-height: $md-size;
            font-size: $md-size * 0.5;

            .mat-icon {
                font-size: math.div($md-size, 1.25);
                height: $md-size;

                margin-top: -4px;
                padding-right: calc($gutter / 3);
                line-height: $md-size;
                width: auto;
            }
        }
        &.lg-size {
            padding: calc($gutter / 3) calc($gutter / 1.25);
            min-height: $lg-size;
            line-height: $lg-size;
            font-size: $lg-size * 0.5;

            .mat-icon {
                font-size: math.div($lg-size, 1.25);
                height: $lg-size;

                margin-top: -4px;
                padding-right: calc($gutter / 3);
                line-height: $lg-size;
                width: auto;
            }
        }
        &.xl-size {
            padding: calc($gutter / 1.5) $gutter;
            min-height: $xl-size;
            line-height: $xl-size;
            font-size: math.div($xl-size, 2.5);
        }

        &.bordered {
            border-width: 2px !important;
            border-style: solid !important;
            width: calc(calc($gutter * 2));
            height: calc($gutter * 2);
            .mat-icon {
                font-size: calc($gutter * 1.25);
                position: relative;
                top: -8px;
            }
        }
    }
}

.light-bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.no-border {
    border: 0;
    outline: none;
}

.border-box {
    box-sizing: border-box;
}

.vertical-align-middle {
    vertical-align: middle;
}

.sticky-col-right {
    position: sticky;
    right: 0;
    z-index: 2;
}

th.sticky-col-right {
    z-index: 3;
}

[disabled] {
    pointer-events: none;
}

.mat-icon {
    &.xl-size {
        font-size: math.div($xl-size, 1.25);
        height: $xl-size;
        line-height: $xl-size;
        width: auto;
    }
}

.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        padding: 20px;
    }

    .mat-dialog-actions {
        .mdc-button {
            margin-top: 20px;
        }
    }
}
