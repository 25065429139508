@use 'sass:math';

.mat-default {
    &.mat-mdc-raised-button,
    &.mat-raised-button {
        background-color: $saleyard-dark-grey;
        border: 1px solid $saleyard-dark-grey;
        color: $saleyard-white;
        svg {
            * {
                stroke: $saleyard-white !important;
                fill: $saleyard-white !important;
            }
        }
    }
    &.mat-mdc-outlined-button {
        border-color: $saleyard-dark-grey;
    }
    &[disabled] {
        opacity: 0.4;
    }
}

.start-price {
    background-color: black !important;
}

button,
.button,
.mat-mdc-menu-item,
.like-button {
    &.sm-size {
        padding: calc($gutter / 3) calc($gutter / 3);
        font-size: $sm-size;

        .mat-icon {
            font-size: math.div($sm-size, 1.25);
            height: $sm-size;

            margin-top: -2px;
            padding-right: calc($gutter / 3);
            line-height: $sm-size;
            width: auto;
        }
    }
    &.md-size {
        padding: calc($gutter/2.5) calc($gutter/1.25);
        min-height: $md-size;
        line-height: $md-size;
        font-size: $md-size * 0.5;

        .mat-icon {
            font-size: math.div($md-size, 1.25);
            height: $md-size;

            margin-top: -4px;
            padding-right: calc($gutter / 3);
            line-height: $md-size;
            width: auto;
        }
    }
    &.lg-size {
        padding: calc($gutter / 3) calc($gutter/1.25);
        min-height: $lg-size;
        line-height: $lg-size;
        font-size: $lg-size * 0.5;

        .mat-icon {
            font-size: math.div($lg-size, 1.25);
            height: $lg-size;

            margin-top: -4px;
            padding-right: calc($gutter / 3);
            line-height: $lg-size;
            width: auto;
        }
    }
    &.xl-size {
        padding: calc($gutter/1.5) $gutter;
        min-height: $xl-size;
        line-height: $xl-size;
        font-size: math.div($xl-size, 2.5);
    }

    &.bordered {
        border-width: 2px !important;
        border-style: solid !important;
        width: calc($gutter * 2);
        height: calc($gutter * 2);
        .mat-icon {
            font-size: calc($gutter * 1.25);
            position: relative;
            top: -9px;
            left: -10px;
        }
    }

    &[disableripple] {
        .mat-button-ripple,
        .mat-button-focus-overlay {
            display: none !important;
        }
    }
}

button,
.button {
    text-transform: uppercase !important;
    &:hover {
        text-decoration: none !important;
    }
}

.menu-btn:not(.close) {
    .mat-mdc-button-ripple,
    .mat-button-focus-overlay {
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
    }

    .mat-icon {
        font-size: $lg-size;
    }
}

.home-link {
    position: absolute;
    bottom: $gutter;
    left: (calc($gutter * 2))-3;
    color: $saleyard-primary;
    z-index: 3;
}

.auction-main-controls-wrap {
    position: relative;

    .mat-icon {
        font-size: 24px;
        margin-top: 12px;
        width: 24px;
    }
}

.quick-announcement {
    .mat-mdc-card {
        padding: 10px;
        padding-top: calc($gutter * 2);
        padding-bottom: 0;

        button {
            position: absolute;
            top: 0;
            right: 0;
        }

        textarea {
            line-height: 14px;
        }
    }

    .table {
        tbody {
            .mat-icon {
                top: 4px;
                position: relative;
            }
        }
    }
}

.pen-number-wrap {
    position: relative;
    .pen-number-check {
        position: absolute;
        top: calc($gutter / 3);
        right: 0;
        .mat-mdc-progress-spinner {
            &,
            svg {
                width: $gutter !important;
                height: $gutter !important;
            }
        }
    }
}

.menu-btn {
    position: absolute;
    top: $gutter;
    left: $gutter;
    z-index: 3;
}

.mat-mdc-button-persistent-ripple {
    display: none;
}

.button-with-loader {
    position: relative;
    .button-loader {
        position: absolute;
        top: calc($gutter / 3);
        right: 0;
        .mat-mdc-progress-spinner {
            &,
            svg {
                width: $gutter !important;
                height: $gutter !important;
            }
        }
    }
}

.clickable,
.hg-button,
a,
button,
.keyboard-outer-wrap,
.mat-button-wrapper {
    &,
    & * {
        -ms-touch-action: manipulation !important;
        touch-action: manipulation !important;
    }
}

.mat-button-toggle {
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: normal;
        padding: 6px 6px;
        font-size: $sm-size;
    }

    .mat-primary.mat-raised .mat-button-toggle-label-content {
        color: $saleyard-white;
        background-color: $saleyard-primary;
    }

    .mat-primary.mat-stroked .mat-button-toggle-label-content {
        color: $saleyard-primary;
    }
    :disabled {
        opacity: 0.4;
    }
}

.btn-no-style {
    &,
    &:hover,
    &:focus {
        background-color: transparent;
        text-decoration: none;
        border: 0;
    }
}

.text-right {
    margin-right: 10px;
}

.date-text {
    margin-top: 0px;
}

.pr-10 {
    padding-right: 10px !important;
}

.button-add-remove-pick {
    display: block;
    height: 80px;

    .mat-icon {
        margin: 0;
    }
}

.button-fw-300 {
    font-weight: 300;
}
