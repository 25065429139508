.float-left {
    float: left;
}

.rounded-0 {
    border-radius: 0 !important;
}

.outline-0 {
    outline: none !important;
}

.d-block {
    display: block !important;
}

.d-flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-none {
    display: none !important;
}

@media screen and (min-width: 1280px) and (max-width: 5000px) {
    .d-table-cell {
        display: table-cell !important;
    }
}

.w-100 {
    width: 100%;
}

.w-90 {
    margin-left: 10%;
    width: 90%;
}

.square {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.bg-img {
    background-position: center center;
    background-repeat: no-repeat;
}

.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.clickable {
    cursor: pointer;
}

.none-clickable {
    &,
    & * {
        cursor: default !important;
        pointer-events: none !important;
    }
}

.img-preload {
    visibility: hidden !important;
    width: 1px;
    height: 1px;
}

.border-danger {
    border-color: red !important;
}

.wrap-white-space {
    white-space: normal !important;
}

.wrap-white-space-lg-down {
    @media only screen and (max-width: 1024px) {
        white-space: normal !important;
    }
}

.min-w-100-lg-down {
    @media only screen and (max-width: 1024px) {
        min-width: 100% !important;
    }
}

.button-height-70 {
    display: block !important;
    height: 70px !important;
}

.button-fix-status {
    background: #51a351 !important;
    color: $saleyard-white !important;
    border: solid 1px #51a351 !important;
    cursor: pointer;
    display: grid !important;
    align-items: center;
    font-size: 19px;
    height: 36px;
    justify-content: center;
    padding: 8px 12px;
    border-radius: 5px;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    z-index: 100;
    width: 100%;
}

.button-fix-status__icon {
    position: absolute;
    left: -30px;
    top: 0;
}

.button-spinner-loader {
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
