.auction-card {
    background-color: $saleyard-primary;
    color: $saleyard-white;
    text-align: center;
}

.open-icon {
    margin: $gutter auto;
    margin-top: 0;
    height: calc($gutter * 3) !important;
    width: calc(calc($gutter * 3)) !important;
    svg {
        height: calc($gutter * 3) !important;
        width: calc($gutter * 3) !important;
    }
    @media only screen and (min-width: 1400px) {
        margin: calc($gutter * 2) auto;
        height: calc($gutter * 4) !important;
        width: calc($gutter * 4) !important;
        svg {
            height: calc($gutter * 4) !important;
            width: calc($gutter * 4) !important;
        }
    }
}

.sub-title {
    font-size: 1.25em;
    @media only screen and (min-width: 1400px) {
        font-size: 2em;
    }
}
