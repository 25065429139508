.as-purchaser {
    bottom: 10px;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #d9d9d9;
    height: calc(50% - 90px);
    left: 98px;
    position: absolute;
    padding: 16px;
    width: calc(55% - 84px);

    .as-purchaser__title {
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 32px;
    }

    .as-purchaser__item {
        font-size: 30px;
        padding: 8px 16px;
    }
}
