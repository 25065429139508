.mat-expansion-indicator::after {
    color: #d8400e;
}

.closed-panel .mat-expansion-indicator::after {
    color: #fff;
}

.mat-expansion-panel-body {
    padding-bottom: 30px !important;
}
