html,
body {
    height: 100%;
}
body {
    margin: 0;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.hide-lt-md {
    @media screen and (max-width: 959px) {
        display: none;
    }
}

.hide-xs {
    @media screen and (max-width: 599px) {
        display: none;
    }
}
