@use '@angular/material' as mat;
@include mat.core();

@import 'ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap');

$bidr-font-family: 'Nunito';
$bidr-typography: mat.m2-define-typography-level(
    $font-family: $bidr-font-family,
    $font-weight: 500,
    $font-size: 15px,
    $line-height: 1,
    $letter-spacing: 0px,
);

$custom-typography: mat.m2-define-typography-config(
    $font-family: $bidr-font-family,
    $button:
        mat.m2-define-typography-level(
            $font-size: 16px,
            $font-weight: 300,
        ),
);

@include mat.all-component-typographies($custom-typography);
@include mat.button-typography($custom-typography);

$dark-primary-text: rgba(black, 1);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$bidr-orange: (
    50: #faeae8,
    100: #fdcebf,
    200: #fdae95,
    300: #fc8f6b,
    400: #fc774b,
    500: #d8400e,
    600: #f15b29,
    700: #e35424,
    800: #d54d21,
    900: #bb411a,
    A100: #8c9eff,
    A200: #536dfe,
    A400: #3d5afe,
    A700: #304ffe,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

$bidr-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$bidr-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// Background for light themes.
$bidr-app-background-color: #f8f8f8;

$my-primary: mat.m2-define-palette($bidr-orange);
$my-accent: mat.m2-define-palette($bidr-orange, 700, 800, 900);

$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
        ),
    )
);

@include mat.all-component-themes($my-theme);
