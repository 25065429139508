@use 'sass:math';

.keyboard-outer-wrap {
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-moz-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-o-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @-ms-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    input {
        border: 0;
        font-size: 2em;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    &.dot-disabled {
        [data-skbtn='.'] {
            pointer-events: none !important;
            opacity: 0.4;
        }
    }
    &.num-disabled {
        [data-skbtn='1'],
        [data-skbtn='2'],
        [data-skbtn='3'],
        [data-skbtn='4'],
        [data-skbtn='5'],
        [data-skbtn='6'],
        [data-skbtn='7'],
        [data-skbtn='8'],
        [data-skbtn='9'],
        [data-skbtn='0'],
        [data-skbtn='00'],
        [data-skbtn='000'] {
            pointer-events: none !important;
            opacity: 0.4;
        }
    }

    .keyboard-input-form-field-wrap {
        padding: $gutter calc($gutter * 1.5);
        margin: $gutter (calc($gutter / 3));
        position: relative;
        &.prepend .unit {
            left: calc($gutter / 3);
        }
        &.append .unit {
            right: calc($gutter / 3);
        }
        .unit {
            position: absolute;
            top: 50%;
            margin-top: -(calc($gutter / 3));
        }
    }
    .hg-row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        & > .hg-button {
            border-radius: $borderRadius;
            -ms-flex-preferred-size: 0;
            flex-basis: 0;
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            max-width: 100%;
            margin: calc($gutter / 3);
            padding: calc($gutter / 1.5);
            @media only screen and (min-height: 800px) {
                padding: $gutter;
            }
            @media only screen and (max-width: 800px) {
                margin: calc($gutter / 3);
            }
            text-align: center;
            font-size: 1.75em;
            border: 1px solid $saleyard-dark-grey;
            cursor: pointer;
        }
        [data-skbtn='{backspace}'] {
            background-image: url('/assets/icons/backspace-arrow.svg');
            background-position: center center;
            background-size: calc($gutter * 2) auto;
            background-repeat: no-repeat;
        }
    }

    &.disabled {
        .simple-keyboard {
            .hg-button,
            input {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }
}

.Lot-increment .Lot-increment,
.ManagerBid-amount .ManagerBid-amount,
.Lot-startPrice .Lot-startPrice,
.Lot-callPrice .Lot-callPrice,
.LotStartPrice-startPrice .Lot-startPrice,
.LotCallPrice-callPrice .Lot-callPrice {
    animation: pulse 1.5s linear infinite;
}

.ManagerBid-amount:disabled {
    animation: none;
}

.Lot-startPrice .Lot-startPrice,
.Lot-aveWeight .Lot-aveWeight,
.Lot-tally .Lot-tally,
.LotDetail-tally .Lot-tally,
.LotDetail-aveWeight .Lot-aveWeight,
.LotStartPrice-startPrice .Lot-startPrice {
    .value {
        display: inline-block;
        position: relative;

        &::before {
            @include pseudo();
            background-color: $saleyard-primary;
            z-index: -1;
            border-radius: 4px;
            top: -(calc($gutter * 0.2));
            left: -(calc($gutter * 0.2));
            right: -(calc($gutter * 0.2));
            bottom: -(calc($gutter * 0.2));
            animation: pulse 2.5s linear infinite;
        }
    }
}

.Lot-increment .Lot-increment,
.ManagerBid-amount .ManagerBid-amount,
.Lot-startPrice .Lot-startPrice,
.LotStartPrice-startPrice .Lot-startPrice {
    background-color: $saleyard-dark-grey !important;
    color: $saleyard-white !important;
}

.Lot-callPrice .Lot-callPrice,
.LotCallPrice-callPrice .Lot-callPrice {
    background-color: $saleyard-primary !important;
    color: $saleyard-white !important;
}

@keyframes spinner {
    0% {
        margin-left: 0%;
    }
    100% {
        margin-left: 20%;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
