// https://cucumberprod.visualstudio.com/bidr/_sprints/taskboard/bidr%20Team/bidr/Picks%20-%20Iteration%205%20UAT%20-%20Runs?workitem=55555
// https://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela

// .table-wrap,.table-container{
//     &,*{
//         -webkit-transform: translate3d(0, 0, 0) translateZ(0);
//     }
// }

.force-rerender {
    -webkit-transform: translate3d(0, 0, 0) translateZ(0) !important;
}
