/* Carousel styles */
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
.owl-dots {
    $dot-width: 6px;
    $dot-height: 6px;
    $dot-margin: 4px;
    $dot-background: #c3c3c3;
    $dot-background-active: #3c4c54;
    $dot-rounded: 6px;

    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
        display: inline-block;
        zoom: 1;

        span {
            width: $dot-width;
            height: $dot-height;
            margin: $dot-margin;
            background: $dot-background;
            display: block;
            -webkit-backface-visibility: visible;
            transition: opacity 200ms ease;
            border-radius: $dot-rounded;
        }

        &.active,
        &:hover {
            span {
                background: $dot-background-active;
            }
        }
    }
}
