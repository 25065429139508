.bg-dark {
    background-color: $saleyard-grey;
}

.bg-primary {
    background-color: $saleyard-primary !important;
}

.bg-white {
    background-color: $saleyard-white;
}

.bg-grey {
    background-color: $saleyard-grey;
}

.bg-light-grey {
    background-color: $saleyard-light-grey;
}
