table {
    &.striped,
    &.table-striped {
        tr:nth-of-type(even) td {
            background-color: rgba($saleyard-grey, 0.25);
            border-bottom: 2px solid $saleyard-white;
            border-top: 1px solid #ddd;
        }
        tr:nth-of-type(odd) td {
            background-color: rgba($saleyard-grey, 0.05);
            border-bottom: 2px solid $saleyard-white;
            border-top: 1px solid #ddd;
        }
    }
    &.sticky-header {
        thead {
            th {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 3;
            }
        }
    }
    &.hover-table {
        tr:hover td {
            background-color: rgba($saleyard-primary, 0.5) !important;
        }
    }
    .action-cell {
        overflow: hidden;
    }
    > tbody > tr > td {
        vertical-align: middle;
    }
}

.table {
    margin-bottom: unset;
}

.table-wrap.multi-select-lot-list {
    overflow: auto;
}

@media screen and (max-width: 1025px) {
    table.auctions-table th,
    table.auctions-table td {
        padding: 0 6px 0 0 !important;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        height: 40px !important;
        width: 40px !important;
        padding: 6px;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        height: 40px !important;
        width: 40px !important;
    }
}

table.auctions-table th:first-of-type,
table.auctions-table td:first-of-type {
    padding-left: 24px !important;
}
