@use 'sass:math';

.lot-header-group {
    position: relative;
    .lot-link {
        display: none !important;
        position: absolute;
        top: -(calc($gutter / 3));
        right: 0;
        padding-right: 0;
        padding-top: 0;
        .mat-icon {
            width: 10px;
            margin-right: 3px;
        }
    }
    h1 {
        font-size: 35px !important;
        line-height: 35px !important;
        padding: calc($gutter / 1.5) 0;
        @media only screen and (max-width: 800px) {
            font-size: 25px !important;
            line-height: 25px !important;
            padding: calc($gutter / 3) 0;
            padding-right: calc($gutter * 2);
        }
    }
}

.lot-header {
    padding-top: 0 !important;
}

@media screen and (max-width: 599px) {
    .lot-header {
        padding-bottom: 0 !important;
    }
}

.desktop-lot-link {
    position: fixed;
    left: 15px;
    top: 158px;
    width: 55px;
    text-align: center;

    @media screen and (max-width: 1280px) {
        display: none;
    }

    a {
        display: block;
        padding: 4px 10px;
        border: 1px solid $saleyard-primary;
        border-radius: 4px;
        .mat-icon {
            margin-left: 0 !important;
            top: 1px !important;
            position: relative;
        }
    }

    small {
        display: none !important;
    }
}
