.as-lot-selling {
    align-items: center;
    background: #fff;
    border: solid 3px #d8400e;
    display: grid;
    font-size: 36px;
    gap: 8px;
    height: 350px;
    margin-bottom: 16px;
    padding: 16px;

    .text-primary {
        font-size: 40px;
        font-weight: bold;
    }

    &-item {
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 1fr;
        font-weight: bold;
        text-align: left;
    }

    &-item:last-child {
        margin-top: 16px;
    }

    &-item span:last-child {
        text-align: right;
    }

    .auctioneer-screen__ll-item:last-child {
        margin-top: 0;
    }
}
