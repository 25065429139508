.circle-inverted {
    border-radius: 100% !important;
    background-color: $saleyard-primary;
    padding: 10px;
    margin-right: 10px;
    color: $saleyard-white;
    position: relative;
    top: 2px;
    height: auto !important;
    width: auto !important;
}
